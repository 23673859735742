'use client';

import { useTranslations } from 'next-intl';
import { z } from 'zod';
import { makeZodI18nMap } from './zodErrorsMap';

export const useI18nZodErrors = () => {
	const t = useTranslations('zod');

	z.setErrorMap(
		makeZodI18nMap({
			t,
		}),
	);
};
