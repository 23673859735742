import React from 'react';
import { Input } from '@chakra-ui/react';
import { FieldError, FieldValues, Path, RegisterOptions, UseFormRegister } from 'react-hook-form';

import { Field } from '@/components/ui/field';

interface RegisterInputProps<TFieldValues extends FieldValues> {
	name: Path<TFieldValues>;
	title?: string;
	register: UseFormRegister<TFieldValues>;
	error: FieldError | undefined;
	type?: string;
	registerOptions?: RegisterOptions<TFieldValues, Path<TFieldValues>>;
	disabled?: boolean;
	isRequired?: boolean;
	placeholder?: string;
	isForModal?: boolean;
	onFocus?: () => void;
	component?: React.ElementType;
}

export default function RegisterInput<TFieldValues extends FieldValues>({
	name,
	title,
	register,
	registerOptions,
	error,
	type = 'text',
	disabled = false,
	isRequired = false,
	placeholder,
	isForModal = false,
	component: Component = Input, // Rename to Component here
}: RegisterInputProps<TFieldValues>) {
	return (
		<Field label={title} invalid={!!error} errorText={error?.message} required={isRequired}>
			<Component
				id={String(name)}
				type={type}
				{...register(name, {
					setValueAs: (v) => {
						if (v === '') {
							return null;
						}
						return v;
					},
					...registerOptions,
				})}
				disabled={disabled}
				placeholder={placeholder}
				_placeholder={{ color: 'fg.lowContrast' }}
				bg="bg.card"
				h={isForModal ? '40px' : '48px'}
				px="16px"
				textStyle="md"
				fontWeight="400"
				color="fg.normalContrast"
				borderWidth={1}
				borderColor="fg.border"
			/>
		</Field>
	);
}
